<template>
  <div id="app">
    <div class="background-image"></div>
    <RouterView class="router-view"/>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  position: fixed; /* Geändert von 'relative' zu 'fixed' */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.background-image {
  opacity: 0.07;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/background4.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: fadeIn 1.5s ease-in;
  z-index: -1; /* Setzt den Hintergrund hinter alle anderen Inhalte */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
