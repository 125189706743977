import { createRouter, createWebHistory } from 'vue-router'


const   routes = [
    {
        name: 'InProgress',
        component: () => import('../components/InProgressView.vue'),
        path: '/'
    },

];
const router = createRouter({
    history: createWebHistory(),
    routes
})


export default router
